import Header from '../components/header/Header'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'


const Home = () => {
    const navigate = useNavigate()

    localStorage.removeItem('id')
    localStorage.removeItem('token')
    localStorage.removeItem('user_role')
    localStorage.removeItem('user_name')
    
    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/')
          }, 1500);
          return ()=> clearTimeout(timer);
    }, [])

    return (
        <>
            <div className="container ac">
                    <h1 className="fw-bold bdr bg-light op-3">Prašom prisijungti iš naujo!</h1>
            </div>
        </>
    )
}

export default Home