import Header from '../components/header/Header'
import SchoolList from '../components/schoolList/SchoolList'
import Message from '../components/message/Message'
import axios from 'axios'
import { useState, useEffect } from 'react'
import Search from '../components/search/Search'

const Home = () => {
    const [searchResults, setSearchResults] = useState([])
    const [loading, setLoading] = useState(true)
    const [schools, setSchools] = useState([])
    const [message, setMessage] = useState({
        text: '',
        status: ''
    })

    useEffect(() => {
        setLoading(true)
        axios.get('https://projektukas.online/api/schools')
        .then(resp => {
            setLoading(false)
            if(resp.data.success) {
                setSchools(resp.data.message)
                setSearchResults(resp.data.message)
            }
        })
        .catch(err => {
            setLoading(false)
            if(err.response.data)
                setMessage({text: err.response.data.message, status: 'danger'})
            else 
                setMessage({text: 'Serveris miręs', status: 'danger'})
        })
    }, [])
    // console.log(searchResults)


    return (
        <>
            <Header schools={schools} setSearchResults={setSearchResults}/>
            {loading && ( <div className="loading">Kraunasi...</div> )}
            <div className="container fw-bold mt1-120">
                <Search schools={schools} setSearchResults={setSearchResults}/>
                <Message value={message} />
                {schools ? <SchoolList searchResults={searchResults} />
                 : (<h2>Nėra registruotų mokymo įstaigų</h2>)}
                
            </div>
        </>
    )
}

export default Home