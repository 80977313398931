import { useState } from 'react'
import SchoolCard from '../schoolCard/SchoolCard'
import Message from '../message/Message'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'


import './SchoolList.css'

const SchoolList = ({ searchResults }) => {
    const schools = searchResults
    const token = localStorage.getItem('token')
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const handleOrder = (id) => {
        setLoading(true)
        localStorage.setItem('id', id)
        navigate('/student/register')
    }
    // console.log(schools)

    return loading ? ( <div className="loading">Kraunasi...</div> ) : (
        <>
            <div className="card1 d-flex align-self-center">
                {schools.map(school => <SchoolCard key={school.id} data={school} handleOrder={handleOrder} />)}
            </div>
        </>
    )
}

export default SchoolList