import React from 'react';
import './Footer.css'

const Footer = () => (
    <footer className="container text-lg-center">
        <div className="text-lg-center ">
            <p className="mt-5 mb-3 h6 text-light fw-bold">&copy;{(new Date().getFullYear())} EDISON  </p>
        </div>
    </footer>   
    
);
export default Footer;
